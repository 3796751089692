import React from 'react'
import './HeroSection.css'

function HeroSection() {
  return (
    <div className='hero-container'>
      <div className='hero-photo'>
          <div className='hero-typewriter'>
              <h1>
                LEKARZE SPECJALIŚCI
              </h1>
          </div>
          <p>
            Poniedziałek - Czwartek  13:00 - 18:00
            <br />
            Piątek 13:00 - 16:00
            <br />
            ul. Fryderyka Chopina 11 65-031 Zielona Góra
            <br />
            <a href="tel:+48 68 353 44 91" >
            tel: +48 68 353 44 91
            </a>
          </p>
          <div className='nasi-lekarze'>
            Nasi lekarze:
          </div>
      </div>
      <h3>
        Godziny otwarcia:
        <br />
        Poniedziałek - Czwartek 13:00 - 18:00
        Piątek 13:00 - 16:00
      </h3>
    </div>
  )
}

export default HeroSection