import React from 'react'
import OfferCard from './OfferCard'
import Data from './Offer.json'
import Data2 from './Clinics.json'
import './Offer.css'
import ClinicCard from './ClinicCard'

function Offer() {
  return (
    <>
    <div className='offer-container'>
      {Data.map(post =>{
        return(
          <div key={post.id} className='offer-card'>
             <OfferCard  src={post.source} type={post.type} description={post.description} treated1={post.treated1} treated2={post.treated2} treated3={post.treated3} treated4={post.treated4} />
          </div>
        )
      })}
      {Data2.map(post =>{
        return(
          <div key={post.id} className='offer-card'>
             <ClinicCard  src={post.source} type={post.type} description={post.description} treated1={post.treated1} treated2={post.treated2} treated3={post.treated3} treated4={post.treated4} />
          </div>
        )
      })}
    </div>
    <div className='offer-contact-box'>
        <h1>
          Dane kontaktowe:
        </h1>
        <p>
        <a href="tel:+48 68 353 44 91" >
            tel: +48 68 353 44 91
            </a>
          <br />
          e-mail: rejestracja@prokardio.pl
          <br />
          ul. Fryderyka Chopina 11
          <br />
          65-031 Zielona Góra
        </p>
        <div className='map-button'>
          <a href="https://goo.gl/maps/eCEcwGneBtF2gEnz7" >
          <p>Otwórz mapę</p>
          </a>
        </div>
        </div>
   
    </>
  )
}

export default Offer