import React from 'react'
import './Footer.css'

function Footer() {
  
  return (
    <>
    <div className='footer-container'>
      <div className='footer-copyright'>
        Copyright &copy; 2022 by ProKardio. Wszelkie prawa zastrzeżone.
      </div>

      <div className='footer-author'>
      Fryderyka Chopina 11,
      <br /> 65-031 Zielona Góra
      <br />
      +48 68 353 44 91
      </div>
    </div>
    </>
  )
}

export default Footer