import React from 'react'
import './Contact.css'


function Contact() {
  return (
    <>
      <div className='contact-container'>
        <div className='left'>
        <div className='contact-box'>
        <h1>
          Dane kontaktowe:
        </h1>
        <p>
        <a href="tel:+48 68 353 44 91" >
            tel: +48 68 353 44 91
            </a>
          <br />
          e-mail: rejestracja@prokardio.pl
          <br />
          ul. Fryderyka Chopina 11
          <br />
          65-031 Zielona Góra
        </p>
        <div className='map-button'>
          <a href="https://goo.gl/maps/eCEcwGneBtF2gEnz7" >
          <p>Otwórz mapę</p>
          </a>
        </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.5162557986832!2d15.504613099999997!3d51.94277659999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470613ca870d5421%3A0xe027e25d354bee71!2sProKardio%20Poradnia%20Specjalistyczna!5e0!3m2!1spl!2spl!4v1648394054309!5m2!1spl!2spl" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map" className='map-container'></iframe>
      </div>
      <div className='right'>
        <img src="./prokardiowejscie.jpg" alt='wejscie'/>
      </div>
      </div>
    </>
  )
}

export default Contact
